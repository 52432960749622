.Container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #07b274;
   margin: 0;
   padding: 0;
   width: 100%;
  }
  
  .login {
  
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    justify-content: space-around;
  }
  .login-form{
    width:40%;
  }
  .login-img{
   width:50%;
  }
  h1 {
    margin-top: 0;
    text-align: center  ;
  }
  
  label {
    margin-top: 10px;
    font-weight: 400;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  input[type="text"],
  input[type="password"] {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  }
  
  .btn {
    padding: 6px 16px;
    white-space: nowrap;
    background-color:#07b274;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .btn:hover {
    background-color:#07432deb;
    color: white;
  }
  