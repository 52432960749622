
@media (max-width: 991px){
  .sidebar .nav > li.active.active-pro {
    position: relative;
    bottom: unset;
  }
}

#bodyClick {
  position: absolute;
}
