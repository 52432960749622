/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 10px 20px;
  /* color: mediumseagreen; */
  background-color: white;
  font-size: small;
  width: 95%;
  align-self: flex-end;
}
.pageno {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.975rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  margin: 0 0 0 20px;
}
.rowsperpage{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.975rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 5px;
}

/* .pagination > button {
  margin: 5px;
  border: none;
  color: rgb(0, 0, 0);
  padding: 4px;
  background-color: white;
} */
.pagination input,
select {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.975rem;
  text-align: center;
  /* margin: 5px; */
  /* color: mediumseagreen; */
  height: 29px;
  /* border:none; */
}

.name {
  text-align: center;
  padding: 10px;
  font-size: 20px;
  background-color: rgb(11, 11, 53);
  margin-bottom: 5px;
  color: rgb(255, 255, 255);
}





ul{
  list-style-type: none;
}
.fixed-plugin .button-container .github-btn {
  display: inline-block;
  font-size: 14px;
}
/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/

.fixed-plugin .SocialMediaShareButton {
  display: inline-block;
  margin: 0 2px;
}
.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

.places-buttons .btn {
  margin-bottom: 30px;
}
.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.sidebar .nav li .nav-link p{
  font-size: 13px;
  /* font-family: 'Poppins'; */
  font-style: normal;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}
.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #302f2f;
  font-weight: 400;
  padding-bottom: 5px;
  border-top: none !important;
  border-bottom: none;
  text-align: center !important;
}
 tbody > tr > td, .table > tfoot > tr > td {
  padding: 12px 8px;
  vertical-align: middle;
  text-align: center ;
}

.fixed-plugin {
  position: absolute;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}
.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}
.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 10px;
  padding: 0 10px;
}
.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}
.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}
.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}
.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white {
  background-color: #ffffff;
}
.fixed-plugin .badge-black {
  background-color: #1dc7ea;
}
.fixed-plugin .badge-azure {
  background-color: #1dc7ea;
}
.fixed-plugin .badge-green {
  background-color: #87cb16;
}
.fixed-plugin .badge-orange {
  background-color: #ffa534;
}
.fixed-plugin .badge-purple {
  background-color: #9368e9;
}
.fixed-plugin .badge-red {
  background-color: #fb404b;
}
.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}
.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  text-align: center;
}
.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  /* float: left; */
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}
.fixed-plugin .adjustments-line .switch {
  float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
  background-color: transparent;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}
.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  width: 50%;
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}
.fixed-plugin .btn-social i {
  margin-right: 5px;
}
.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  opacity: 1;
}

@media (min-width: 992px) {
  .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 27px;
    opacity: 0;

    transform-origin: 0 0;
  }
  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    transform-origin: 0 0;
  }

  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
  }

  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -15px;
  }

  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }

  .fixed-plugin {
    top: 300px;
  }
}

@media (max-width: 991px) {
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }
  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }

  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }

  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }

  .fixed-plugin li.header-title {
    height: 40px;
  }
  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}
.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}
.btn-social.btn-round {
  padding: 9px 10px;
}
.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}
.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}
.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}
.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}
.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}
.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}
.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

.places-buttons .btn {
  margin-bottom: 30px;
  
}
.sidebar:after{
  background: #07b274!important;
  /* background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%); */
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}
.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.sidebar .nav > li.active-pro a {
  background: rgba(68, 236, 73, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}
.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}
.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}
.gh-btn {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fcfcfc),
    color-stop(100%, #eee)
  );
  background-image: -webkit-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -moz-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -ms-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: -o-linear-gradient(top, #fcfcfc 0, #eee 100%);
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}
.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #eee),
    color-stop(100%, #ddd)
  );
  background-image: -webkit-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -moz-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -ms-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: -o-linear-gradient(top, #eee 0, #ddd 100%);
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}
.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}
.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.gh-count {
  position: relative;
  display: none; /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}
.gh-count:hover,
.gh-count:focus {
  color: #4183c4;
}
.gh-count:before,
.gh-count:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}
.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}
.github-btn-large {
  height: 30px;
}
.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}
.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}
.github-btn-large .gh-count {
  margin-left: 6px;
}
.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}
.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}
.typography-line span {
  bottom: 10px;
  color: #9a9a9a;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  left: 5px;
  position: absolute;
  width: 260px;
  text-transform: none;
}
.typography-line {
  padding-left: 15%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}
/* profile */
.model {
  display: none; /* Hidden by default */
}
/* .model-open { */
  /* Hidden by default */
  /* position: fixed; 
  z-index: 10; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: scroll; 
  background: rgba(13, 13, 13, 0.3);
  backdrop-filter: blur(2.5px); */
  /* Black w/ opacity */
  /* scroll-snap-type: none; */
/* } */
.close.profile{
  float: left;
  font-size: 2rem;
  font-weight: 700;
  padding-top: 10px;
  line-height: 1;
  color: #f2f8f2;
  text-shadow: 0 2px 0 #000;
  opacity:1;
  cursor: pointer;
}
.jobProfileContainer > .compo2 {
  width: 27%;
}
.modalContainer{
 
  padding-top: 1%;
}
.card.profile{
  border-radius:20px
}
.card-image.profile{
  border-radius: 20px 20px 5px 5px;
}
.user-details{
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 5px;
}
.user-details > p {
  font-size: 14px;
  margin: 0px;
  margin-right: 15px
}
.user-details > span {
  font-size: 13px;
}

.about > strong {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  margin-left: 14px;
}
.about > h6,.projects>h6{
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  /* margin-left: 12px; */
}
.about > p,.ongoing-projects>p{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.925rem;
  padding: 0 12px 0 12px;
}
/* .hidealldta{
  height: 0;
 
  transition: all 0.3 linear;
} */
.hidealldta{
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
}
.card-use{
  /* width: 95%; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.336) 0px 1px 4px;
}
.card-user{
  /* width: 95%; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.336) 0px 1px 4px;
}
.invoice-container{
  position: absolute;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 900px;
  background: #4d4d4d91;
}
.invoice-card{
  margin: 2% auto;
    padding: 0 20px 300px 20px;
    /* border: 1px solid rgb(136, 136, 136);
    box-shadow: rgba(0, 0, 0, 0.336) 0px 2px 5px; */
    border: none;
  border-radius: 0;
  position: absolute;
  top:0;
  left: 0;
  
}
.invoice-heading {
  display: flex;
  justify-content: center;
}
.invoice-heading > h2{
  /* font-family: 'Corbel'; */
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  color: #606563;
  margin: 15px 0 15px;
  letter-spacing: 2px;
}
.addr-heading{
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #7c8c2b;
  /* margin: 15px 0 15px; */
}
.addr{
  font-size: 16px;
  font-style: normal;
  /* font-weight: 600; */
  /* color: #7c8c2b; */
}
.download-button{
  font-size: 20px;
  margin-right: 20px;
    /* color:rgb(146, 145, 145); */
    color:rgba(7, 178, 116, 1);
}
 .download-button:hover{
  font-size: 20px;
  margin-right: 20px;
    /* color:rgba(7, 178, 116, 1); */
    color:rgb(146, 145, 145);
    cursor:pointer;
}
.table-invoice > tbody> tr> td{
  border: 0.2px solid #d7b703e5 ;
  padding: 5px;
  height: 40px !important;
  vertical-align: middle;
  text-align: center ;
}
.table-invoice > thead> th{
  border: 0.2px solid #d7b703e5 ;
  padding: 5px;
  color: #7c8c2b;
  height: 40px !important;
  font-weight: 600;
  vertical-align: middle;
  text-align: center !important;
}
.table-invoice {
  font-size: 14px;
  padding: 10px;
}
.ongoing-projects> h5{
  font-weight: 600;
  margin-bottom: 0px;
}

.skillsset-card > h5,.technicalset-card > h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 45px;
  margin: 10px 15px 0 15px;
  color: rgb(71, 95, 130);
}
.skillsset-card>ul> li{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    flex-shrink: 0;
    
}
.skillsset-card> small{
  font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    flex-shrink: 0;
    margin: 10px 15px 0 42px;
    
}
@media (max-width:991px) {

  .modalContainer{
    margin-left: 1%;
    padding-top: 3%;
  }
  .user-details > p {
    font-size: 12px;
  }
  .user-details > span {
    font-size: 12px;
  }
  .about> p,.ongoing-projects>p{
    font-size: 12px;
  }
  .ongoing-projects> h6,.about>h6{
    font-size: 14px;
  }
  .ongoing-projects> h5,.ongoing-projects>span{
    font-size: 13px;
  }

}

.projects > hr {
  border-top: 1px solid #c6c6c6;
}

.projects > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.projects > strong {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  margin-left: 14px;
}


.jobMatch > span {
  border-width: 4px;
  padding: 8px 15px;
  margin-top: 30px;
  margin-right: 10px;
  margin-left: 12px;
  cursor: pointer;
  
}

.heading{
  font-weight: 500;
  font-size: 35px;
  margin-top: 25px;
  padding-right: 60px;
}


.card-details {
  display: flex;
  flex-wrap: wrap;
}

.Projectdetail-container {
  font-size: large;
  display: flex;
  flex-wrap: wrap;
  margin: 35px;
  line-height: 33px;
}

.flex-container {
  display: flex;
  flex-basis: 50%;
  flex-wrap: wrap;
}

.flex-item {
  
  flex: 1;
  white-space: nowrap; /* Added to prevent content from wrapping within the item */
}

.head, .head2 {
  font-weight: bold;
  margin-right: 5px;
}

.item {
  margin-left: 15px;
  flex-wrap: wrap;
}


.close.profile {
  float: left;
}



.jobContainer {
  position: relative;
  /* width: 800px; */
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  box-sizing: border-box;
}
.jobContainer > hr {
  border-top: 1px solid #c6c6c6;
}
.jobContainer > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.job-container-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-container-header> span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.job-container-header.ongoing > span{
  color: rgba(7, 178, 116, 1);
}
.job-container-header > button{
  font-weight: 600;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 20px;
  background-color:rgb(249, 250, 250);
  color:rgba(7, 178, 116, 1);
  border: 1px solid rgba(7, 178, 115, 0.534);
}
.job-container-header> button:hover{
  color:rgb(249, 250, 250);
  background-color:rgba(7, 178, 116, 1);
  border: 1px solid rgba(7, 178, 115, 0.925);
}

.jobContainer {
  width: 100%;
}
.bittingCard {
  border: 1px solid #c6c6c6;
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
  margin:5px 0;
}

.current-jobs {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  overflow: hidden;
  transition: all .3s ease;
}
.current-jobs > div:first-child {
  flex: 8;
}
.current-jobs > div > .job-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  cursor: pointer;
}

.row-view {
  display: flex;
}
.row-view > div {
  width: 45%;
}
.current-jobs > div > p {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #606563;
  width: 90%;
  margin-top: 4px;
}
.current-jobs > div:last-child {
  display: flex;
  flex-direction: column;
}
.avg-bite {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.avg-bite > strong {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.avg-bite > small {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #8f8f8f;
}
.total-bits {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #606563;
  text-align: left !important;
}
.current-tags > span {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: black;
  padding: 5px 10px;
  background-color: rgb(163 230 206);
  margin-right: 10px;
  border-radius: 10px;
}
.current-tags {
  margin: 8px 0;
}
#keywords {
  margin: 20px 0 0 0;
}
.model {
  display: none; /* Hidden by default */
}
.model-open {
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background: rgba(13, 13, 13, 0.3);
  backdrop-filter: blur(2.5px);
  /* Black w/ opacity */
  scroll-snap-type: none;
}
.bidtags > span {
  padding: 5px 10px;
  margin-top: 10px;
}
.biddingInformation {
  display: flex;
  gap: 20px;
}
.verify {
  display: flex;
  gap: 20px;
}
.biddingInformation > div {
  display: flex;
  flex-direction: column;
}
.verify > div {
  display: flex;
  flex-direction: column;
}
.biddingInformation >.bidhead{
  font-size: 15px;
}
.verify>.bidhead{
  font-size: 15px;
}
.biddingProposal{
  font-size: 14px;
}
.proposal{
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid darkgray;
  padding: 20px;
}
#bidheadValue{
  color: #126412;
    font-weight: 900;
    font-size: 25px;
}
#bidheadStatus>div>p{
  color: #d53804;
    font-weight: 600;
    font-size: 22px;
    font-style: normal;
    padding: 15px;
    /* font-family: 'Poppins'; */
}
#bidheadDuration{
  font-weight: 900;
    font-size: 25px;
}
/* Close Button */
.close {
  /* position: relative;
  top:0px;
  right:0px; */
  float: right;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  padding: 40px;
  border: 1px solid #888;
  width: 70%;
  border-radius: 20px;
 
}
.lowSection{
  margin-top: 30px;
}
.forgot-modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px;
}
.modal-content label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  color: #606563;
  line-height: normal;
  display: none;
}
.skillview {
  display: flex;
  flex-wrap: wrap;
   margin-left: 12px; 
}
.skillview > span {
  background-color: #434343;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 2px 4px;
  color: white;
  font-size: 13px;
}
.sideComponent {
  /* margin: 0 0 30px 30px; */
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  /* padding: 10px 10px 10px 10px; */
  /* width: 24%; */
  position: relative;
}
.profileConatiner > .profile-details {
  border-radius: 20px 20px 0px 0;
  background-color: #07b273;
  /* background-image:url("assets/img/desktop2.jpg"); */
  height: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profile-details img {
  width: 100px;
  height:100px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
  margin-bottom: 20px;
}
.profile-details>.editimg img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
  margin-bottom: 20px;
}
.profile-details > .profilename {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.profile-details > .profileDesignation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  color: white;
}
.profile-details > .profileStatus {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fcfdfe;
  margin-top: 2px;
  /* identical to box height */

  text-align: center;
  /* color: white; */
  text-shadow: 0 0 2px yellow, 0 0 3px rgb(205, 134, 155), 0 0 8px rgb(255, 0, 76); /* Create a glowing effect with multiple shadows */

}
.user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-details > p {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(97, 119, 152, 1);
  margin: 8px 0;
}
.user-details > span {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
}
.Rating{
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.username{
  display: flex;
  justify-content: left;
  padding: 10px;
}
.star-rating{
  display: flex;
  justify-content: left;
  padding: 10px;
}
.Rating > .username >div> div> .job-name {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
}
.star-rating >div> button {
  background-color: transparent;
  
  border: none;
  outline: none;
  cursor: pointer;
}
.star-rating-star{
font-size: 30px;
}
.star-rating button.on {
  color: gold;
}
.off {
  color: rgba(224, 223, 255, 0.723);
}
.all-education {
  display: flex;
  margin-top: 20px;
}
.education > strong {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}
.education > strong > img {
  width: 35px;
  margin-left: 10px;
}
.education-line {
  align-items: stretch;
  /* background-color: black; */
  margin-right: 20px;
  border-right: 3px dashed #07b274;
}
.education-content {
  display: flex;
}
.eduction-time {
  margin-bottom: 15px;
}
.gender{
  margin: 10px 0 0 30px;
  display: grid;
  
}
.gender label{
  line-height:1 ;
  margin-bottom: 5px;
  margin-right: 10%;
  margin-left: 10px;
}
.gender span{
  margin-left: 12px;
}
.card .card-category, .card label {
  font-size: 14px;
  font-weight: 400;
  color: #625e5e;
  margin-bottom: 4px;
}
.radio{
  display: flex;
  justify-content: center;
  align-items: first baseline;
}
.radio > input{
  width: 20%;
  margin: 0;
}
.type {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.type_job{
  display: flex;
  justify-content: space-between;
  padding-inline-end: 70px;
}
.type_job > input{
  margin-left: 20px;
}
.type > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.8px solid rgb(138, 135, 135);
  width: 49.5%;
  height: 190px;
  /* background-color: #07b27361; */
}
.type > div > span {
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(96, 101, 99, 1);
}
.type > div > strong {
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.typeSelect {
  background-color: green;
  /* Add any other styling you want for the selected type */
}
.education > strong {
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}
.education > strong > img {
  width: 35px;
  margin-left: 10px;
}
.modal-content label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  color: #606563;
  line-height: normal;
  display: none;
}
.model-field input,
.model-field textarea,
.model-field select {
  padding: 10px 10px;
  width: 95%;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}
.model-field select{
  width:90%
}
.model-field input[type="submit"] {
  width: 20%;
  outline: none;
  border: none;
  background: rgba(7, 178, 116, 1) !important;
}
.editimg > img {
  width: 50px;
  position: absolute;
    top: 11px;
    right: 26px
}
 /* .dragdrop {
  text-align: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}
.dragdrop:hover {
  border-color: #42b983;
}
.FileUploader {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
}
.FileUploader:hover {
  background-color: #e0e0e0;
}

.FileUploader input[type="file"] {
  display: none;
}
.dragdrop h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.dragdrop p {
  font-size: 16px;
  margin-top: 10px;
}

/* Style for the "Upload CSV Data" button */
/* .button-upload {
  margin-top: 10px;
} */

/* Style for the "Download Sample CSV Format" button */
/* .button-download {
  margin-top: 10px;
} */ 
.drag-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #cccccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  margin: 20px;
}

.drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #00f790;
  border-radius: 4px;
  cursor: pointer;
}

.drop-zone p {
  margin: 0;
  font-size: 16px;
  color: #555555;
}

.biddiv{
  display: flex;
  align-items: center;
  gap: 20px;
}
.rate-card{
  margin: 0 15px 0 15px;
}

.profileConatiner > .profile-details > .radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #EEE;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 350px;
  font-size: 14px;
}

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #c8e9ec;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  /* padding: 0.35px; */
  width: 270px;
  font-size: 14px;
}

.radio-inputs > label.radio {
  margin: 6px;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .status {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  /* padding: 14px 58px 14px 83px; */
  padding: 0.5rem 2.2rem;
  /* padding: 10px 50% 10px 50%; */
  /* margin-left: 8px; */
  /* margin-right: 2px; */
  color: rgba(51, 65, 85, 1);
  transition: all .15s ease-in-out;
}

.radio-inputs .radio input:checked + .status {
  background-color: rgba(7, 178, 115, 0.725);
  font-weight: 600;
  /* padding: 10px; */
}
.button {
  width: fit-content;
  display: flex;
  padding: 0.4em 1rem;
  cursor: pointer;
  gap: 0.5rem;
  font-weight: bold;
  border-radius: 30px;
  text-shadow: 2px 2px 3px rgba(0, 61, 136, 0.5);
  background: linear-gradient(15deg, #880088, #aa2068, #cc3f47, #de6f3d, #f09f33, #de6f3d, #cc3f47, #aa2068, #880088) no-repeat;
  /* background-image: linear-gradient(15deg, rgb(67, 191, 174), rgb(8, 25, 53), rgb(81, 188, 218)); */
  background-size: 300%;
  color: #fff;
  border: none;
  margin: 10px;
  background-position: left center;
  box-shadow: 0 30px 10px -20px rgba(0,0,0,.2);
  transition: background .3s ease;
}

.button:hover {
  background-size: 320%;
  background-position: right center;
}

.button:hover svg {
  fill: #fff;
}

.button svg {
  width: 23px;
  fill: #f09f33;
  transition: .3s ease;
}
 .featuredd > svg {
  width: 32px;
  fill: #f09f33;
  transition: .3s ease;
  filter: drop-shadow(0 0 3px yellow) drop-shadow(0 0 4px yellow) drop-shadow(0 0 8px yellow);

}

@media (max-width: 1450px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
.biddingInformation{
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.verify{
  display: flex;
  flex-direction: column;
  gap: 14px;
}

#bidheadStatus>div>p{
  color: #d53804;
    font-weight: 600;
    font-size: 22px;
    font-style: normal;
    padding: 0;
    /* font-family: 'Poppins'; */
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 10px 10px 30px;
  /* color: rgb(43, 202, 115); */
  background-color: white;
  font-size: small;
  width: 95%;
  align-self: flex-end;
}
.biddiv{
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  gap: 20px;
}

.profile-details > .profilename {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
}

@media (max-width: 1200px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
.biddingInformation{
  display: flex;
  flex-direction: row;
}
#bidheadStatus>div>p{
  color: #d53804;
    font-weight: 600;
    font-size: 22px;
    font-style: normal;
    padding: 0;
    /* font-family: 'Poppins'; */
}
.biddingInformation{
  gap: 90px;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 10px 10px 30px;
  /* color: rgb(43, 202, 115); */
  background-color: white;
  font-size: small;
  width: 95%;
  align-self: flex-end;
}
.biddiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile-details > .profilename {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px; 
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
}
@media (max-width: 991px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
.biddingInformation{
  display: flex;
  flex-direction: row;
}
.biddingInformation{
  gap: 100px;
}
.biddiv{
  /* display: flex; */
  align-items: center;
  gap: 20px;
}
}



.userdeatail_container {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  padding: 0 40px;
  margin-bottom: 15px;
}

#rate_type {
  width: 100%;
}

.userdeatail_container input {
  padding: 10px 0 10px 10px;
  border: 1px solid hsl(0, 0%, 84%);
  color: #767676;
  width: 97%;

  background: #ffffff;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.userdeatail_container .radio {
  display: flex;
  justify-content: space-between;
}

.userdeatail_container .radio .radio_span {
  display: flex;
  align-items: center;
}

.userdeatail_container .radio .radio_span label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #8e8e8e;
  line-height: normal;
}

.userdeatail_container .radio .radio_span input {
  margin-bottom: 0;
}

.bid-status .radio-inputs-user {
  width: 175px;
  font-size: 12px;

  & .radio .status {
    padding: 0.4rem 1.2rem;
  }
}


.typesandbudget {
  display: grid;
  grid-template-columns: 50% 40%;
  line-height: 25px;
}

.typesandbudget span {
  text-transform: capitalize;
  font-size: 12px;
}



.bid_Form {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.bid_Form>label {
  width: 28%;
  margin-right: 200px;
  box-sizing: border-box;
}

.bid_Form.upper>label {
  margin-right: 0px;
}

.bid_Form input,
.bid_Form textarea {
  padding: 10px 10px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
}

.bid_Form.upper input,
.bid_Form.upper textarea {
  padding: 0px;
  border: none;
  font-family: poppins;
}

.add {
  height: 40px;
  /* width:30px; */
}

.remove {
  height: 42px;
}


.milestone_Form {
  justify-content: flex-start;
}

.milestone_Form>label {
  width: 45%;
  padding-right: 40px;
  box-sizing: border-box;
}

.milestone_Form.upper>label {
  padding-right: 0px;
}

